







































































import { defineComponent } from '@vue/composition-api';
import EditProfileForm from '@/components/molecules/EditProfileForm.vue';

export default defineComponent({
  name: 'SelfProfile',

  components: {
    EditProfileForm,
  },

  props: {
    profile: {
      type: Object,
      default: () => ({
        name: 'LOREM_IPSUM',
        description: 'TEST123',
        role: 'CREWMATE',
        team_id: '1b03aa8e-0937-42c7-b8f1-27c21c47aabb',
        user_id: 'auth0|12345678910',
        score: 0,
        contribution: 0,
        team: {
          motto: 'TEST_MOCK_OG_1',
          name: 'MOCK_OG_!',
          picture_url: 'https://picsum.photos/200/300',
          scores: [{ score: 50 }],
        },
      }),
    },
  },
});
