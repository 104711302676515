















































































































import { defineComponent } from '@vue/composition-api';
import BuyPicometer from '@/components/molecules/BuyPicometer.vue';
import BuyViewfinder from '@/components/molecules/BuyViewfinder.vue';
import ArtifactInfo from '@/components/molecules/ArtifactInfo.vue';

export default defineComponent({
  name: 'Shop',

  components: {
    BuyPicometer,
    BuyViewfinder,
    ArtifactInfo,
  },

  props: {
    artifactDetails: {
      type: Object,
      default: () => ({
        picometer: true,
        viewfinder: true,
        score: 0,
      }),
    },
  },

  setup() {
    const picometer = {
      name: 'Picometer Microscope',
      ability: 'Gives you the name of one crewmate in your team.',
      description: 'This microscope is so precise it can help you identify your true allies. '
        + 'Unfortunately some of its parts were damaged, so it has limited capacity.',
    };
    const viewfinder = {
      name: 'Viewfinder',
      ability: 'Gives you a clue to help you find the impostor.',
      description: 'A peculiar viewfinder which can detect impostor trails.',
    };

    return {
      picometer,
      viewfinder,
    };
  },
});
