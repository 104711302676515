










































import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'ArtifactInfo',

  components: {
  },

  props: {
    itemName: {
      type: String,
      default: () => 'Nothing',
    },
    ability: {
      type: String,
      default: () => 'Does not really do anything.',
    },
    description: {
      type: String,
      default: () => 'Seriously. It does nothing.',
    },
  },

  setup() {
    const dialog = ref(false);

    return {
      dialog,
    };
  },
});
