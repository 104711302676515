

































































import { defineComponent, reactive, ref } from '@vue/composition-api';
import { GetOneParticipantDetailsDocument, useUpdateParticipantDetailsMutation } from '@/generated/graphql';
import { useApolloClient } from '@vue/apollo-composable';
import CacheService from '@/services/cacheService';

export default defineComponent({
  name: 'EditProfileForm',

  components: {
  },

  props: {
    description: {
      type: String,
    },
    username: {
      type: String,
    },
  },

  setup(props, { root }) {
    // Create apollo client for caching purposes
    const { resolveClient } = useApolloClient();
    const client = resolveClient();

    const dialog = ref(false);
    const profile = reactive({
      name: props.username || '',
      description: props.description || '',
      auth0_id: root.$auth.user?.sub || '',
    });

    const { mutate: updateDetails } = useUpdateParticipantDetailsMutation(() => ({}));
    function saveWrapper() {
      dialog.value = false;
      console.log('Updated profile', profile);

      updateDetails(profile).then((result) => {
        // Initiate caching service
        const cache = new CacheService(client);

        const participants = result.data.update_participants.returning;
        cache.write(GetOneParticipantDetailsDocument, { participants });
      });
    }

    return {
      dialog,
      saveWrapper,
      profile,
    };
  },
});
