








































import { defineComponent } from '@vue/composition-api';
import EmergencyVotingButton from '@/components/atoms/EmergencyVoting.vue';

export default defineComponent({
  name: 'EmergencyMeetingCard',
  components: {
    EmergencyVotingButton,
  },
  props: {
    meetingParticipant: {
      type: Object,
      default: () => ({
        emergency_vote: false,
        imposter_vote_count: 0,
        name: 'Test User',
        status: 'ALIVE',
        user_id: '0',
        team: {
          number: 0,
        },
      }),
    },
    emergencyVote: {
      type: Boolean,
      default: true,
    },
  },

});
