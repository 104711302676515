



















import { defineComponent } from '@vue/composition-api';
import EmergencyMeetingCard from '@/components/molecules/EmergencyMeetingCard.vue';

export default defineComponent({
  name: 'EmergencyMeeting',

  components: {
    EmergencyMeetingCard,
  },

  props: {
    meetingParticipants: {
      type: Array,
      default: () => [{
        emergency_vote: false,
        imposter_vote_count: 0,
        name: 'Test User',
        status: 'ALIVE',
        user_id: '0',
        team: {
          number: 0,
        },
      }],
    },
    emergencyVote: {
      type: Boolean,
      default: true,
    },
  },
});
