




























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'EmergencyMeetingResult',

  components: {
  },

  props: {
    deadParticipants: {
      type: Array,
      default: () => [{
        name: 'HUMAN2077',
        role: 'IMPOSTOR',
      }, {
        name: 'Bob',
        role: 'CREWMATE',
      }],
    },
  },
});
